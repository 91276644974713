@import '_mixin';

@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Bricolage+Grotesque:opsz,wght@12..96,200..800&display=swap");

html,
body {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  overflow: hidden;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

.ant-btn-primary {
  background-color: #1677FF !important;
  &:hover {
    background-color: #1677ffb2 !important;
  }
}

.ant-form-item {
  margin-bottom: 20px !important;
}
.ant-input, .ant-select-selector, .ant-input-number {
  border-radius: 3px !important;
}
.ant-switch[aria-checked="false"] {
  background-color: red !important; 
}
.button-green {
  background-color: #28A745;
  color: white;
}
.button-green:hover {
  background-color: #28a746dc;
  color: white !important;
  border-color: #28a746dc !important;
}
.mg-bttom-0 {
  margin-bottom: 0!important;
}