@import '@styles/_mixin.scss';

.main {
  position: relative;
  background-color: #f0f2f5;
  &-sidebar {
    width: 256px;
    height: 100vh !important;
    overflow-y: scroll;
  }
}
.main-wrap {
  position: absolute;
  width: calc(100vw - 255px);
  left: 257px;
  transition: all 0.15s linear;
  height: 100vh;
  overflow-y: scroll;
  &-collapsed {
    position: absolute;
    width: calc(100vw - 78px);
    left: 80px;
    transition: all 0.25s linear;
    height: 100vh;
    overflow-y: scroll;
  }
}
.logo-collapsed {
  height: 24px !important;
  position: relative;
}

.logo-dis-collapsed {
  height: 30px!important;
  position: relative;
  // margin-top: 10px;
}

.navbar {
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 2%;
  height: 60px;
  margin-bottom: 20px;
  &-icon {
    font-size: 26px;
  }
}

.main-content {
  background-color: #f0f2f5;
  width: 100%;
  min-height: calc(100vh - 60px);
  padding: 16px;
}
.main-spin {
  margin-left: 20px;
  margin-right: 20px;
}

.ant-menu-dark > li:first-child {
  margin-top: 15px !important;
  margin-bottom: 50px !important;
}
.ant-menu-item.ant-menu-item-selected {
  border-radius: 0 !important;
  margin-left: 0 !important ;
  margin-right: 0 !important ;
  width: 100% !important;
}

@include responsive_latop {
  .main-sidebar {
    width: 240px;
  }
  .main-wrap {
    width: calc(100vw - 249px);
    left: 241px;
    height: 100vh;
    &-collapsed {
      width: calc(100vw - 78px);
      left: 80px;
    }
  }
  .logo-collapsed {
    // height: 25px !important;
    // position: relative;
  }

  .logo-dis-collapsed {
    // height: 42px !important;
    // position: relative;
    // transform: translateX(calc(50% - 18px));
  }

  .navbar {
    padding: 10px 2%;
    height: 60px;
    margin-bottom: 20px;
    &-icon {
      font-size: 26px;
    }
  }

  .main-content {
    width: 100%;
    min-height: calc(100vh - 60px);
    padding: 16px;
  }
  .main-spin {
    margin-left: 20px;
    margin-right: 20px;
  }
}

@include responsive_tablet {
  .main-sidebar {
    width: 200px;
  }
  .logo-collapsed {
    // height: 22px !important;
    // position: relative;
  }
  .logo-dis-collapsed {
    // height: 40px !important;
    // position: relative;
    // transform: translateX(calc(50% - 15px));
  }
  .main-wrap {
    width: calc(100vw - 199px);
    left: 201px;
    height: 100vh;
    &-collapsed {
      width: calc(100vw - 78px);
      left: 80px;
    }
  }

  .navbar {
    padding: 10px 2%;
    height: 60px;
    margin-bottom: 20px;
    &-icon {
      font-size: 26px;
    }
  }

  .main-content {
    width: 100%;
    min-height: calc(100vh - 60px);
    padding: 16px;
  }
  .main-spin {
    margin-left: 20px;
    margin-right: 20px;
  }
}

@include responsive_mobile {
  .main-sidebar {
    width: 180px;
  }
  .logo-collapsed {
    // height: 30px !important;
    // position: relative;
  }

  .logo-dis-collapsed {
    // height: 50px !important;
    // position: relative;
    // transform: translateX(calc(50% - 20px));
  }
  .main-wrap {
    width: calc(100vw - 179px);
    left: 181px;
    height: 100vh;
    &-collapsed {
      width: calc(100vw - 78px);
      left: 80px;
    }
  }

  .navbar {
    padding: 10px 2%;
    height: 60px;
    margin-bottom: 20px;
    &-icon {
      font-size: 26px;
    }
  }

  .main-content {
    width: 100%;
    min-height: calc(100vh - 60px);
    padding: 16px;
  }
  .main-spin {
    margin-left: 20px;
    margin-right: 20px;
  }
}
