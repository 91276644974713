.uploadv2 {
    padding: 20px;
}
.upload-no-url {
    cursor: pointer;
}
.custom-image-input {
    border-radius: 10px;
    border: 1px solid #f5f5f5;
    object-fit: cover;
}
.custom-file-input {
    display: none !important;
}
.upload-url {
    position: relative;
}
.upload-image-show {
    border-radius: 10px;
    border: 1px solid #f5f5f5;
    object-fit: cover;
}
.upload-button {
    position: absolute;
    border-radius: 50%;
    width: 44px;
    height: 42px;
    background-color: #ffffff6e;
    &-delete {
        top: 50%;
        left: 70%;
        transform: translate(-50%, -50%);
    }
    &-edit {
        top: 50%;
        left: 30%;
        transform: translate(-50%, -50%);
    }
    &-icon {
        font-size: 20px;
        position: relative;
        left: -3px;
        top: 2px;
    }
}